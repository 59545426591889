(function ($) {

    // jQuery test
    if (window.jQuery) {
        console.log('jQuery is working...');
    }

    /**
     * AOS
     */
    window.onload = function () {
        AOS.init({
            once: true,
            disable: 'mobile'
        });
    }

    // Magnific popup
    $('.popup-with-zoom-anim').magnificPopup({
		type: 'inline',

		fixedContentPos: true,
		fixedBgPos: true,

		overflowY: 'auto',

		closeBtnInside: true,
        preloader: true,
        tClose: 'Закрыть',
		
		midClick: true,
		removalDelay: 300,
        mainClass: 'my-mfp-zoom-in',
    });

    // Btn close popup
    $('.close,.magnific-close').on( "click", function() {
        $.magnificPopup.close();
    });
    
    $(window).scroll(function() {    // this will work when your window scrolled.
		var height = $(window).scrollTop();  //getting the scrolling height of window
		if(height > 0) {
			$(".header").addClass('scroll');
		} else{
			$(".header").removeClass('scroll');
		}
    });
    
    $('#tel').mask('+38 (000) 000-00-00');

})(jQuery);