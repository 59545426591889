$(function () {

    // Slick reviews
    $('.slick-reviews').slick({
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        variableWidth: false,
        autoplay: false,
        autoplaySpeed: 3000,
        pauseOnFocus: false,
        pauseOnHover: false,

        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
        ]
    });

    $('.slick-offers').slick({
      dots: true,
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: false,
      autoplay: false,
      autoplaySpeed: 3000,
      pauseOnFocus: false,
      pauseOnHover: false,

      responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
              breakpoint: 768,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
      ]
  });
});
