$(function () {
    

    /**
     * Responsive menu
     */
    var burger = $('#burgerIcon'),
        menu = '.site-header__menu';

    burger.click(function () {
        $(this).toggleClass('burger-icon--open');
        $('body').toggleClass('overflow');
        $(menu).toggleClass('site-header__menu--open');
    });

    $(document).ready(function(){
        $(".site-header__menu-list").on("click","a", function (event) {
            event.preventDefault(); //опустошим стандартную обработку
            var id  = $(this).attr('href'), //заберем айдишник блока с параметром URL
                top = $(id).offset().top; //определим высоту от начала страницы до якоря
                topSmall = $(id).offset().top - 180;

                if($(this).attr('href') == '#category'){
                    $('body,html').animate({scrollTop: topSmall}, 1000); //сделаем прокрутку за 1 с
                }else{
                    $('body,html').animate({scrollTop: top}, 1000);
                }

        $(menu).removeClass('site-header__menu--open');
        $('body').removeClass('overflow');
        $(burger).removeClass('burger-icon--open');
        });
    });

});